import {ToolbarSlot} from "@react-pdf-viewer/toolbar";
import {Button} from "@material-ui/core";
import {DownloadIcon} from "@react-pdf-viewer/get-file";
import React from "react";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function pdfToolBar(Toolbar: (props: ToolbarProps) => ReactElement, pdfDownload, pptDownload, pptDownloadLoading) {
    return (<Toolbar>
        {(slots: ToolbarSlot) => {
            const {
                CurrentPageInput,
                EnterFullScreen,
                Download,
                GoToNextPage,
                GoToPreviousPage,
                NumberOfPages,
                Print,
                ShowSearchPopover,
                Zoom,
                ZoomIn,
                ZoomOut
            } = slots;

            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%'
                    }}
                >
                    <div style={{padding: '0px 2px'}}>
                        <ShowSearchPopover/>
                    </div>
                    <div style={{padding: '0px 2px'}}>
                        <ZoomOut/>
                    </div>
                    <div style={{padding: '0px 2px'}}>
                        <Zoom/>
                    </div>
                    <div style={{padding: '0px 2px'}}>
                        <ZoomIn/>
                    </div>
                    <div style={{padding: '0px 2px', marginLeft: 'auto'}}>
                        <GoToPreviousPage/>
                    </div>
                    <div style={{ padding: '0px 2px', width: '4rem' }}>
                        <CurrentPageInput />
                    </div>
                    <div style={{ padding: '0px 2px' }}>
                        / <NumberOfPages />
                    </div>
                    <div style={{padding: '0px 2px'}}>
                        <GoToNextPage/>
                    </div>

                    <div style={{padding: '0px 2px', marginLeft: 'auto'}}>
                        <EnterFullScreen/>
                    </div>
                    <div style={{padding: '0px 2px'}}>
                        <button
                            className="rpv-core__minimal-button"
                            title="Download PDF"
                            aria-label="Download PDF"
                            onClick={pdfDownload}
                        >
                            <DownloadIcon/>
                        </button>
                    </div>
                    <div style={{padding: '0px 2px'}}>
                        <Print/>
                    </div>

                    <div style={{padding: '0px 10px'}}>
                        <button
                            className="btn btn-primary btn-sm px-2 py-1"
                            title="Download PDF"
                            aria-label="Download PDF"
                            onClick={pptDownload}
                            disabled={pptDownloadLoading}
                        >
                            <i className={pptDownloadLoading ? 'fa fa-spinner fa-spin mr-1' : 'fa fa-download mr-1'} aria-hidden="true"></i>
                            Download PPT File
                        </button>
                    </div>
                </div>
            );
        }}
    </Toolbar>)
};